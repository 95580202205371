import React from "react";
import { Routes, Route } from "react-router-dom"
import { Layout } from "./components/Layout";
import { MintPage } from "./pages/mint";
import { CheckerPage } from "./pages/checker";


function App() {

        return (
            <>
                <Routes>
                    <Route path="/" element={<Layout />}>
                        <Route path="/" element={<MintPage />} />
                        <Route path="/wlchecker" element={<CheckerPage />} />
                    </Route>
                </Routes>
            </>

        );
    }

export default App;
