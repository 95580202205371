import React, { useState, useEffect } from 'react';
import axios from 'axios';


const CheckerPage = () => {

    const [ethAddress, setEthAddress] = useState('');
    const [isWL, setIsWL] = useState();
    const [error, setError] = useState(true);
    const [isBtnActive, setIsBtnActive] = useState(true);
    const [remainingTime, setRemainingTime] = useState(30);

    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        setEthAddress(inputValue);
        if (inputValue.length === 42) {
            setError(false);
        } else {
            setError(true);
        }
    };

    useEffect(() => {
        let timer;
        if (!isBtnActive && remainingTime > 0) {
            timer = setInterval(() => {
                setRemainingTime((prevTime) => prevTime - 1);
            }, 1000);
        } else if (remainingTime === 0) {
            setIsBtnActive(true);
        }
        return () => clearInterval(timer);
    }, [isBtnActive, remainingTime]);

    const getData = async () => {
        if (!error && isBtnActive) {
            setIsBtnActive(false);
            try {
                await axios.get(
                    `https://api.setoshi.xyz/findHexProof/${ethAddress.toLowerCase()}`
                ).then(res => {
                    if (res.data.error) {
                        console.log("Error")
                        setIsWL(false)
                    } else {
                        console.log("Done")
                        setIsWL(true)
                    }
                })
            } catch (error) {
                console.log('Error', error.name + " " + error.code)
            }
        }
    };

    return (
        <>
            <div class="mint-box unselectable">
                <div class="mint">
                    {
                        isWL ? [
                            <div class="wl-box">
                                <div class="wl-box__text">You Are whitelisted!</div>
                                <div class="wl-box__rules">
                                    stay tuned in  <a href="https://twitter.com/setoshiNFT" className='twitterlink' target="_blank" rel="noreferrer">twitter</a>  for any minting updates
                                </div>
                            </div>
                        ] : [
                            isWL === false ? [
                                <div class="wl-box red">
                                    <div class="wl-box__text">You Are not whitelisted!</div>
                                    <div class="wl-box__rules">
                                        <a href="https://twitter.com/intent/tweet?text=hey guys, interact with this tweet if you want @setoshiNFT whitelist %0Dbecause i want it too %0D%23setoshifindme" className='twitterlink' target="_blank" rel="noreferrer">Share a tweet (click),</a> and we could select you for a special opportunity. No guarantees, but then again, why not? Take a chance... or not. Your decision!
                                    </div>
                                </div>
                            ] : [

                            ]
                        ]
                    }

                    <div class="mint-btns">
                        <div className="eth-address-input-container">
                            <input
                                type="text"
                                placeholder="Paste your eth address here"
                                value={ethAddress}
                                onChange={handleInputChange}
                                className="eth-address-input"
                            />
                        </div>
                        {
                            isBtnActive ? [
                                <div class="mint-btn" onClick={getData}>
                                    check
                                </div>
                            ] : [
                                <div class="mint-btn">
                                    {`Timer ${remainingTime}s`}
                                </div>
                            ]
                        }

                    </div>
                </div>
            </div>
            {error && ethAddress.length > 0 && (
                <p className="error-message">Please enter a valid Ethereum address</p>
            )}
        </>
    )
}

export { CheckerPage }