import React from "react";



const Popup = ({ active, setActive, children }) => {

    return (

        <>
            <div className={active ? "popup-bg active" : "popup-bg"} onClick={() => {
                setActive(false);
                sessionStorage.setItem('request', false);
                window.location.reload();
            }}>
                <div className={active ? "popup active" : "popup"} onClick={() => {
                    setActive(false);
                    sessionStorage.setItem('request', false);
                    window.location.reload();
                }}>
                    <div className="popup-content" onClick={e => e.stopPropagation()}>
                        <div className="popup-exit" onClick={() => {
                            setActive(false);
                            sessionStorage.setItem('request', false);
                            window.location.reload();
                        }}>
                            <img src="img/exit.svg" alt="Exit" />
                        </div>
                        {children}
                    </div>
                </div>
            </div>
        </>
    )

}

export default Popup;