/* eslint-disable no-useless-escape */
/* eslint-disable no-regex-spaces */
/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useRef } from 'react';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useAccount, usePrepareContractWrite, useContractWrite, useContractReads, useWaitForTransaction } from 'wagmi';
import contractInterface from "../config/abi.json";
import {
    useConnectModal,
} from '@rainbow-me/rainbowkit';
import { useDebounce } from 'use-debounce';
import Popup from '../components/popup'


const MintPage = () => {
    const toFix = 4;
    const { openConnectModal } = useConnectModal();
    const [NotFUNDS, setNotFUNDS] = useState(false);
    const [MaxMintWL, setMaxMintWL] = useState(false);
    const [MaxMintPb, setMaxMintPb] = useState(false);
    const [UserRejected, setUserRejected] = useState(false);
    const [OnSuccessPopup, setOnSuccessPopup] = useState(false);
    const [OnSuccessWLPopup, setOnSuccessWLPopup] = useState(false);
    const { address, isConnected, isDisconnected, isConnecting } = useAccount();
    const [wlWallet, setwlWallet] = useState(true);
    const [inputValue, setinputValue] = useState(6);
    const [isLoading, setisLoading] = useState(false);
    const [debounceValue] = useDebounce(inputValue, 500);
    const [WLErrors, setWLErrors] = useState({
        INSUFFICIENT_FUNDS: false,
        INVALID_ARGUMENT: false,
        UNPREDICTABLE_GAS_LIMIT: false,
    })
    const [PbErrors, setPbErrors] = useState({
        INSUFFICIENT_FUNDS: false,
        INVALID_ARGUMENT: false,
        UNPREDICTABLE_GAS_LIMIT: false,
    })
    const funcReadName = ['maxSupply', 'getMaxPublicMintPerTransaction', 'getPublicMintCost', 'totalSupply', 'currentPhase', 'getMaxWLMintPerTransaction', 'getWLMintCost', 'getWLMintedCount', 'getPublicMintedCount', 'balanceOf'];
    const mintFuncName = ['publicMint', 'whitelistMint'];
    const contractConfig = {
        CONTRACT_ADDRESS: "0x917dea68f72b639b74e87826d888188fb77d2011"
    }

    const [isContract, setisContract] = useState({
        maxSupply: 6666,
        getMaxPublicMintPerTransaction: 6,
        getPublicMintCost: "7000000000000000",
        totalSupply: 0,
        currentPhase: "0",
        getMaxWLMintPerTransaction: 6,
        getWLMintCost: "6600000000000000",
        getWLMintedCount: 0,
        getPublicMintedCount: 0,
        balanceOf: 0,
    });

    useEffect(() => {
        const getData = async () => {
            if (isConnected) {
                setisLoading(true);
                if (sessionStorage.getItem('request') === 'true') {
                    sessionStorage.getItem('wl') === 'true' ? setwlWallet(true) : setwlWallet(false)
                } else {
                    try {
                        await axios.get(
                            `https://api.setoshi.xyz/findHexProof/${address.toLowerCase()}`
                        ).then(res => {
                            if (res.data.error) {
                                console.log('Ошибка:', res.data.error);
                                setwlWallet(false)
                                sessionStorage.setItem('wl', false)
                            } else {
                                setwlWallet(true);
                                sessionStorage.setItem('wl', true);
                                sessionStorage.setItem('proof', res.data["hexProof"]);
                                sessionStorage.setItem('request', true);
                            }
                        })
                    } catch (error) {
                        console.log('Error', error.name + " " + error.code)
                    }
                }
                setisLoading(false);
            } else {
                sessionStorage.setItem('request', false);
                console.log("Wallet not connected")
            }
        };
        getData();
    }, [isConnected])


    const { config: configpbmint, isError: pbMintError } = usePrepareContractWrite({
        address: contractConfig.CONTRACT_ADDRESS,
        abi: contractInterface,
        functionName: mintFuncName[0],
        args: [debounceValue],
        overrides: {
            value: (isContract.getPublicMintCost * debounceValue).toString(),
        },
        onError(error) {
            if (error.code === 'INSUFFICIENT_FUNDS') {
                setPbErrors({ ...PbErrors, "INSUFFICIENT_FUNDS": true })
            }
            if (error.code === 'INVALID_ARGUMENT') {
                setPbErrors({ ...PbErrors, "INVALID_ARGUMENT": true })
            }
            if (error.code === 'UNPREDICTABLE_GAS_LIMIT') {
                setPbErrors({ ...PbErrors, "UNPREDICTABLE_GAS_LIMIT": true })
            }
            console.log('Error', error.name + " " + error.code)
        },
    })




    const { config: wlmint, isError: WlMintError } = usePrepareContractWrite({
        address: contractConfig.CONTRACT_ADDRESS,
        abi: contractInterface,
        functionName: mintFuncName[1],
        args: [
            debounceValue,
            sessionStorage.getItem('proof') === null ? 'proof' : sessionStorage.getItem('proof').split(','),
        ],
        overrides: {
            value: Number(isContract.getWLMintedCount) < 1 ? (Number(isContract.getWLMintCost) * (debounceValue - 1)).toString() : (Number(isContract.getWLMintCost) * debounceValue).toString(),
        },
        onError(error) {
            if (error.code === 'INSUFFICIENT_FUNDS') {
                setWLErrors({ ...WLErrors, "INSUFFICIENT_FUNDS": true })
            }
            if (error.code === 'INVALID_ARGUMENT') {
                setWLErrors({ ...WLErrors, "INVALID_ARGUMENT": true })
            }
            if (error.code === 'UNPREDICTABLE_GAS_LIMIT') {
                setWLErrors({ ...WLErrors, "UNPREDICTABLE_GAS_LIMIT": true })
            }
            console.log('ProjectError', error.name + " " + error.code)
        },
    })

    const { data: mintData, writeAsync: whitelistMint } = useContractWrite({
        ...wlmint,
        onError(error) {
            if (error.name === 'UserRejectedRequestError') {
                setUserRejected(true)
            }
            console.log('ProjectError', error.name + " " + error.code)
        },
    })
    const { isLoading: ProjectLoading } = useWaitForTransaction({
        hash: mintData?.hash,
        onSuccess(data) {
            setOnSuccessWLPopup(true)
        },
        onError(error) {
            console.log('ProjectError', error.name + " " + error.code)
        },
    })

    const { data: pbdata, writeAsync: publicMint } = useContractWrite({
        ...configpbmint,
        onError(error) {
            if (error.name === 'UserRejectedRequestError') {
                setUserRejected(true)
                setNotFUNDS(false)
            }
            console.log('Error', error.name + " " + error.code)
        },
    })

    const { isLoading: pbLoading } = useWaitForTransaction({
        hash: pbdata?.hash,
        onSuccess(data) {
            setOnSuccessPopup(true)
        },
        onError(error) {
            console.log('Error', error)
        },
    })

    useContractReads({
        contracts: [
            {
                address: contractConfig.CONTRACT_ADDRESS,
                abi: contractInterface,
                functionName: funcReadName[0],
            },
            {
                address: contractConfig.CONTRACT_ADDRESS,
                abi: contractInterface,
                functionName: funcReadName[1],
            },
            {
                address: contractConfig.CONTRACT_ADDRESS,
                abi: contractInterface,
                functionName: funcReadName[2],
            },
            {
                address: contractConfig.CONTRACT_ADDRESS,
                abi: contractInterface,
                functionName: funcReadName[3],
            },
            {
                address: contractConfig.CONTRACT_ADDRESS,
                abi: contractInterface,
                functionName: funcReadName[4],
            },
            {
                address: contractConfig.CONTRACT_ADDRESS,
                abi: contractInterface,
                functionName: funcReadName[5],
            },
            {
                address: contractConfig.CONTRACT_ADDRESS,
                abi: contractInterface,
                functionName: funcReadName[6],
            },
            {
                address: contractConfig.CONTRACT_ADDRESS,
                abi: contractInterface,
                functionName: funcReadName[7],
                args: [address],
            },
            {
                address: contractConfig.CONTRACT_ADDRESS,
                abi: contractInterface,
                functionName: funcReadName[8],
                args: [address],
            },
            {
                address: contractConfig.CONTRACT_ADDRESS,
                abi: contractInterface,
                functionName: funcReadName[9],
                args: [address],
            },
        ],
        watch: true,
        cacheTime: 10000,
        onError(error) {
            console.log('Error', error)
        },
        onSuccess(data) {
            if (data === null) {
                return 0
            }
            if (isDisconnected) {
                console.log("Disconnect")
            } else {
                console.log('Success', data?.toString())
                let result = {}
                funcReadName.map((el, i) => {
                    result[el] = data[i]?.toString()
                    return (true)
                })
                setisContract(result);
            }
        },
    })


    const countElements = useRef([]);

    const handleClick = (index) => {

        countElements.current.forEach((element) => {
            element.classList.remove('active');
        });
        countElements.current[index].classList.add('active');
        const value = index + 1;
        setinputValue(value);
    };


    return (
        <>
            <div className="mint-box">
                <div className="mint">

                    {
                        isConnected ? [
                            isLoading ? [
                                <div className="wl-box yellow">
                                    <p>Checking</p>
                                </div>
                            ] : [
                                wlWallet ? [
                                    <div className="wl-box">
                                        <div className="wl-box__text">You Are whitelisted!</div>
                                        <ol type="1" className="wl-box__rules">
                                            <li>Grab <b>ONE for free</b> and rest for <b>0.0066 ETH each.</b></li>
                                            <li>When you’ve done with your WL ALLOCATION, you can mint public</li>
                                            <li>your wl allocation = <b>6 setoshi</b></li>
                                            <li>your public mint allocation = <b>6 setoshi</b></li>
                                        </ol>
                                    </div>
                                ] : [
                                    <div className="wl-box red">
                                        <div className="wl-box__text">You Are not whitelisted!</div>
                                        <ol type="1" className="wl-box__rules">
                                            <li>If you're sure your wallet is whitelisted, kindly wait as we're gradually adding wallets</li>
                                            <li>your public mint allocation = <b>6 setoshi</b></li>
                                            <li><b>public price = 0.007 eth</b></li>
                                        </ol>
                                    </div>
                                ]
                            ]
                        ] : [
                            <div className="wl-box yellow">
                                <div className="wl-box__text">let’s connect!👇</div>
                                <ol type="1" className="wl-box__rules">
                                    <li>Your <b>whitelist status</b> will be displayed automatically in this window.</li>
                                    <li>You'll see how many <b>setoshi</b> you've got allocated.</li>
                                </ol>
                            </div>
                        ]
                    }


                    {
                        isLoading ? [
                            <div className="cont-info unselectable">
                                <div className="paused">
                                    <div className="pause-img">
                                        <img src="/img/pause.svg" alt="Pause" />
                                    </div>
                                    <div className="wl__info">
                                        <p>wl mint closed</p>
                                        <p>you minted: {isContract.getWLMintedCount} / {isContract.getMaxWLMintPerTransaction}</p>
                                    </div>
                                </div>
                                <div className="paused">
                                    <div className="pause-img">
                                        <img src="/img/pause.svg" alt="Pause" />
                                    </div>
                                    <div className="wl__info">
                                        <p>public mint closed</p>
                                        <p>you minted: {isContract.getPublicMintedCount} / {isContract.getMaxPublicMintPerTransaction}</p>
                                    </div>
                                </div>
                            </div>
                        ] : [
                            isConnected ? [
                                isContract.currentPhase === "0" ? [
                                    <div className="cont-info unselectable">
                                        <div className="paused">
                                            <div className="pause-img">
                                                <img src="/img/pause.svg" alt="Pause" />
                                            </div>
                                            <div className="wl__info">
                                                <p>wl mint closed</p>
                                                <p>you minted: {isContract.getWLMintedCount} / {isContract.getMaxWLMintPerTransaction}</p>
                                            </div>
                                        </div>
                                        <div className="paused">
                                            <div className="pause-img">
                                                <img src="/img/pause.svg" alt="Pause" />
                                            </div>
                                            <div className="wl__info">
                                                <p>public mint closed</p>
                                                <p>you minted: {isContract.getPublicMintedCount} / {isContract.getMaxPublicMintPerTransaction}</p>
                                            </div>
                                        </div>
                                    </div>
                                ] : [
                                    isContract.currentPhase === "1" ? [
                                        wlWallet ? [
                                            <div className="cont-info unselectable">
                                                <div className="paused green">
                                                    <div className="pause-img">
                                                        <img src="/img/green.svg" alt="Mint" />
                                                    </div>
                                                    <div className="wl__info">
                                                        <p>wl mint live</p>
                                                        <p>you minted: {isContract.getWLMintedCount} / {isContract.getMaxWLMintPerTransaction}</p>
                                                    </div>
                                                </div>
                                                <div className="paused green">
                                                    <div className="pause-img">
                                                        <img src="/img/green.svg" alt="Mint" />
                                                    </div>
                                                    <div className="wl__info">
                                                        <p>Public mint live</p>
                                                        <p>you minted: {isContract.getPublicMintedCount} / {isContract.getMaxPublicMintPerTransaction}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ] : [
                                            <div className="cont-info unselectable">
                                                <div className="paused grey">
                                                    <div className="pause-img">
                                                        <img src="/img/green.svg" alt="Mint" />
                                                    </div>
                                                    <div className="wl__info grey">
                                                        <p>wl mint live</p>
                                                        <p>you minted: {isContract.getWLMintedCount} / {isContract.getMaxWLMintPerTransaction}</p>
                                                    </div>
                                                </div>
                                                <div className="paused green">
                                                    <div className="pause-img">
                                                        <img src="/img/green.svg" alt="Mint" />
                                                    </div>
                                                    <div className="wl__info ">
                                                        <p>Public mint live</p>
                                                        <p>you minted: {isContract.getPublicMintedCount} / {isContract.getMaxPublicMintPerTransaction}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ]
                                    ] : [
                                        <div className="cont-info unselectable">
                                            <div className="paused finish">
                                                <div className="pause-img">
                                                    <img src="/img/done.svg" alt="Mint" />
                                                </div>
                                                <div className="wl__info white">
                                                    <p>wl mint finished</p>
                                                    <p>you minted: {isContract.getWLMintedCount} / {isContract.getMaxWLMintPerTransaction}</p>
                                                </div>
                                            </div>
                                            <div className="paused green">
                                                <div className="pause-img">
                                                    <img src="/img/green.svg" alt="Mint" />
                                                </div>
                                                <div className="wl__info ">
                                                    <p>Public mint live</p>
                                                    <p>you minted: {isContract.getPublicMintedCount} / {isContract.getMaxPublicMintPerTransaction}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ]
                                ]
                            ] : [
                                <div className="cont-info unselectable">
                                    <div className="paused">
                                        <div className="pause-img">
                                            <img src="/img/pause.svg" alt="Mint" />
                                        </div>
                                        <div className="wl__info">
                                            <p>wl mint</p>
                                            <p>you minted: {isContract.getWLMintedCount} / {isContract.getMaxWLMintPerTransaction}</p>
                                        </div>
                                    </div>
                                    <div className="paused">
                                        <div className="pause-img">
                                            <img src="/img/pause.svg" alt="Mint" />
                                        </div>
                                        <div className="wl__info">
                                            <p>Public mint</p>
                                            <p>you minted: {isContract.getPublicMintedCount} / {isContract.getMaxPublicMintPerTransaction}</p>
                                        </div>
                                    </div>
                                </div>
                            ]
                        ]


                    }

                    <div className="mint-info">
                        <p>{isContract.totalSupply} / {isContract.maxSupply}</p>

                        {
                            wlWallet ? [
                                Number(isContract.getWLMintedCount) < 1 ? [
                                    <p className="unselectable">{Number((Number(isContract.getWLMintCost / 1000000000000000000) * (inputValue - 1)).toFixed(toFix)) === 0 ? 'Free' : (Number(isContract.getWLMintCost / 1000000000000000000) * (inputValue - 1)).toFixed(toFix) + ' ETH'}</p>
                                ] : [
                                    Number(isContract.getWLMintedCount) >= Number(isContract.getMaxWLMintPerTransaction) ? [
                                        <p className="unselectable">{(Number((isContract.getPublicMintCost / 1000000000000000000) * inputValue).toFixed(toFix))} ETH</p>
                                    ] : [
                                        <p className="unselectable">{(Number((isContract.getWLMintCost / 1000000000000000000) * inputValue).toFixed(toFix))} ETH</p>
                                    ]

                                ]
                            ] : [
                                <p className="unselectable">{(Number((isContract.getPublicMintCost / 1000000000000000000) * inputValue).toFixed(toFix))} ETH</p>
                            ]
                        }
                    </div>
                    <div className="mint-btns">
                        <div className="nums unselectable">
                            <div ref={(el) => (countElements.current[0] = el)} className="num" onClick={() => {
                                setinputValue(1)
                                handleClick(0)
                            }}>1</div>
                            <div ref={(el) => (countElements.current[1] = el)} className="num" onClick={() => {
                                setinputValue(2)
                                handleClick(1)
                            }}>2</div>
                            <div ref={(el) => (countElements.current[2] = el)} className="num" onClick={() => {
                                setinputValue(3)
                                handleClick(2)
                            }}>3</div>
                            <div ref={(el) => (countElements.current[3] = el)} className="num" onClick={() => {
                                setinputValue(4)
                                handleClick(3)
                            }}>4</div>
                            <div ref={(el) => (countElements.current[4] = el)} className="num" onClick={() => {
                                setinputValue(5)
                                handleClick(4)
                            }}>5</div>
                            <div ref={(el) => (countElements.current[5] = el)} className="num active" onClick={() => {
                                setinputValue(6)
                                handleClick(5)
                            }}>6</div>
                        </div>
                        {
                            isConnecting ? [
                                <div className="mint-btn unselectable">
                                    Connecting...
                                </div>
                            ] : [
                                isDisconnected ? [
                                    <div className="mint-btn unselectable" onClick={openConnectModal}>
                                        Connect wallet
                                    </div>
                                ] : [
                                    isContract.currentPhase === "0" ? [
                                        <div className="mint-btn notopen unselectable">
                                            Mint not open yet
                                        </div>
                                    ] : [
                                        wlWallet ? [
                                            Number(isContract.getWLMintedCount) === Number(isContract.getMaxWLMintPerTransaction) ? [
                                                <div className="mint-btn unselectable" onClick={() => {
                                                    if (pbMintError) {
                                                        setNotFUNDS(PbErrors.INSUFFICIENT_FUNDS)
                                                        setMaxMintPb(PbErrors.UNPREDICTABLE_GAS_LIMIT)
                                                    } else {
                                                        !pbLoading ? publicMint() : console.log("Transaction in progress")
                                                    }
                                                }}>
                                                    {
                                                        pbLoading ? [
                                                            <>
                                                                Minting...
                                                            </>
                                                        ] : [
                                                            <>
                                                                Mint now (public)
                                                            </>
                                                        ]
                                                    }
                                                </div>
                                            ] : [
                                                <div className="mint-btn unselectable" onClick={() => {
                                                    if (WlMintError) {
                                                        setNotFUNDS(WLErrors.INSUFFICIENT_FUNDS)
                                                        setMaxMintWL(WLErrors.UNPREDICTABLE_GAS_LIMIT)
                                                    } else {
                                                        !ProjectLoading ? whitelistMint() : console.log("Идет транзакция")
                                                    }
                                                }}>
                                                    {
                                                        ProjectLoading ? [
                                                            <>
                                                                Minting...
                                                            </>
                                                        ] : [
                                                            <>
                                                                Mint now (WL)
                                                            </>
                                                        ]
                                                    }

                                                </div>
                                            ]
                                        ] : [
                                            <div className="mint-btn unselectable" onClick={() => {
                                                if (pbMintError) {
                                                    setNotFUNDS(PbErrors.INSUFFICIENT_FUNDS)
                                                    setMaxMintPb(PbErrors.UNPREDICTABLE_GAS_LIMIT)
                                                } else {
                                                    !pbLoading ? publicMint() : console.log("Transaction in progress")
                                                }
                                            }}>
                                                {
                                                    pbLoading ? [
                                                        <>
                                                            Minting...
                                                        </>
                                                    ] : [
                                                        <>
                                                            Mint now (public)
                                                        </>
                                                    ]
                                                }
                                            </div>
                                        ]
                                    ]
                                ]
                            ]
                        }
                    </div>
                </div>
            </div>
            <Popup active={NotFUNDS} setActive={setNotFUNDS}>
                <div className="popup-text">
                    <div className="popup-title unselectable">
                        <p>not enough eth 🥹</p>
                    </div>
                    <div className="popup-text__text unselectable">
                        <p>Get back to your minting wallet or make sure you've funded this one. <br /><br />
                            P.S. Click on Setoshi in the corner, maybe there's a freemint hidden there? Probably not, of course.</p>
                    </div>
                </div>
            </Popup>
            <Popup active={UserRejected} setActive={setUserRejected} >
                <div className="popup-text">
                    <div className="popup-title unselectable">
                        <p>you declined tx 🥹</p>
                    </div>
                    <div className="popup-text__text unselectable">
                        <p>Not sure if you wanna mint? Give it a try with one, you might thank yourself later.</p>
                    </div>
                </div>
            </Popup>
            <Popup active={MaxMintWL} setActive={setMaxMintWL} >
                <div className="popup-text">
                    <div className="popup-title unselectable">
                        <p>you’ve reached the limit 🫠</p>
                    </div>
                    <div className="popup-text__text unselectable">
                        <p>You can only mint up to {Number(isContract.getMaxWLMintPerTransaction)} NFTs at this stage. It seems like you're attempting to go beyond this limit.</p>
                    </div>
                </div>
            </Popup>
            <Popup active={MaxMintPb} setActive={setMaxMintPb} >
                <div className="popup-text">
                    <div className="popup-title unselectable">
                        <p>you’ve reached the limit 🫠</p>
                    </div>
                    <div className="popup-text__text unselectable">
                        <p>You can only mint up to {Number(isContract.getMaxPublicMintPerTransaction)} NFTs at this stage. It seems like you're attempting to go beyond this limit.</p>
                    </div>
                </div>
            </Popup>
            <Popup active={OnSuccessPopup} setActive={setOnSuccessPopup} >
                <div className="popup-text">
                    <div className="popup-img">
                        <p>🌸🎉🎊🥳</p>
                    </div>
                    <div className="popup-title unselectable">
                        <p>sucessfully minted!</p>
                    </div>
                    <div className="popup-text__text unselectable">
                        <p>congratulations, you’re now a part of the oldest community in the space.</p>
                    </div>
                    <div className="popup-btn-box unselectable">
                        <a href="https://twitter.com/intent/tweet?text=just minted my dope IRL Springfield Punks! super stoked to bring that springfield flavor to the blockchain. dive into the punky vibes and let's ride the springfield wave!🤘💎 %0D%0Dirlspringfield.xyz / %23IRLSpringfieldPunks &related=Nakabitos" className='popup-btn btn-yellow' target="_blank" rel="noreferrer">
                            <p style={{ color: "#000" }} className="unselectable">share on x</p>
                        </a>
                        <a href={`https://etherscan.io/tx/${mintData?.hash}`} className='popup-btn btn-grey' target="_blank" rel="noreferrer">
                            <p style={{ color: "#000" }} className="unselectable">Etherscan</p>
                        </a>
                    </div>
                </div>
            </Popup>
            <Popup active={OnSuccessWLPopup} setActive={setOnSuccessWLPopup} >
                <div className="popup-text">
                    <div className="popup-img">
                        <p>🌸🎉🎊🥳</p>
                    </div>
                    <div className="popup-title unselectable">
                        <p>sucessfully minted!</p>
                    </div>
                    <div className="popup-text__text">
                        <p>congratulations, you’re now a part of the oldest community in the space.</p>
                    </div>
                    <div className="popup-btn-box">
                        <a href="https://twitter.com/intent/tweet?text=i'm setoshi now, don't ask me wtf is this" className='popup-btn btn-yellow' target="_blank" rel="noreferrer">
                            <p style={{ color: "#000" }} className="unselectable">share on x</p>
                        </a>
                        <a href={`https://etherscan.io/tx/${mintData?.hash}`} className='popup-btn btn-grey' target="_blank" rel="noreferrer">
                            <p style={{ color: "#000" }} className="unselectable">Etherscan</p>
                        </a>
                    </div>
                </div>
            </Popup>
        </>
    )
}

export { MintPage }