/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Link, Outlet, useLocation } from "react-router-dom"
import {
    useConnectModal,
    useAccountModal
} from '@rainbow-me/rainbowkit';
import { useAccount, useEnsName } from 'wagmi';
import { useEffect } from 'react';
import $ from "jquery";
import Popup from '../components/popup'

const Layout = () => {
    const [ChainError, setChainError] = useState(false);
    const { openConnectModal } = useConnectModal();
    const { openAccountModal } = useAccountModal();
    const { address, isConnected, isDisconnected } = useAccount();
    const ensName = useEnsName({
        address: address,
        onSuccess(data) {
            console.log('Success', data)
        },
        onError(error) {
            console.log('Error', error)
        },
    })

    const links = {
        Twitter: "https://twitter.com/setoshiNFT",
        Etherscan: "https://etherscan.io/address/0x917dea68f72b639b74e87826d888188fb77d2011",
        OpenSea: "https://opensea.io/collection/setoshi",
    }

    const location = useLocation();
    const { connector: activeConnector } = useAccount()

    useEffect(() => {
        const handleConnectorUpdate = ({ account, chain }) => {
            if (account) {
                console.log('new account', account)
                sessionStorage.setItem('request', false);
                window.location.reload()
            } else if (chain) {
                if (chain.id !== 1) {
                    setChainError(true)
                }
                console.log('new chain', chain)
            }
        }

        if (activeConnector) {
            activeConnector.on('change', handleConnectorUpdate)
        }
    }, [activeConnector])



    const clicker = () => {
        if ($('#buger').hasClass('clicked')) {
            $('.header__burger').removeClass('clicked');
            $('.header__burger').removeClass('active');
            $('.mob-menu').removeClass('active');
        }
    }
    const burger = () => {
        if ($('#buger').hasClass('clicked')) {
            $('.header__burger').removeClass('clicked');
            $('.header__burger').removeClass('active');
            $('.mob-menu').removeClass('active');
        } else {
            $('.header__burger').addClass('clicked');
            $('.header__burger').addClass('active');
            $('.mob-menu').addClass('active');
        }
    }

    const [phrases, setPhrases] = useState([]);

    const [ClickCount, setClickCount] = useState(1);

    useEffect(() => {
        if (phrases.length > 0) {
            const timer = setTimeout(() => {
                setPhrases(prevPhrases => prevPhrases.slice(1));
            }, 1000);

            return () => clearTimeout(timer);
        }
    }, [phrases]);

    const handleClick = () => {
        let newPhrase

        if (ClickCount < 10) {
            newPhrase = "Don't do it."
        }
        if (ClickCount >= 11 && ClickCount < 21) {
            newPhrase = "don't do it please"
        }
        if (ClickCount === 11 ) {
            newPhrase = "stop it"
        }
        if (ClickCount >= 23 && ClickCount < 33) {
            newPhrase = "don't doooo it!"
        }
        if (ClickCount === 22 ) {
            newPhrase = "i said stop it!"
        }
        if (ClickCount === 31 ) {
            newPhrase = "please!"
        }
        if (ClickCount >= 33 && ClickCount < 43) {
            newPhrase = "Seriously, just enjoy the view."
        }
        if (ClickCount >= 43 && ClickCount < 53) {
            newPhrase = "You're persistent, aren't you?"
        }
        if (ClickCount >= 53 && ClickCount < 63) {
            newPhrase = "Okay, this is getting interesting."
        }
        if (ClickCount === 56 ) {
            newPhrase = "pleaseeee!!!! "
        }
        if (ClickCount >= 63 && ClickCount < 73) {
            newPhrase = "Stop it, before it's too late!"
        }
        if (ClickCount === 65 ) {
            newPhrase = "dude, stop it!"
        }
        if (ClickCount >= 73 && ClickCount < 83) {
            newPhrase = "You really like clicking, huh?"
        }
        if (ClickCount === 78 ) {
            newPhrase = "setoshi is angry!!!!"
        }
        if (ClickCount >= 83 && ClickCount < 93) {
            newPhrase = "Is there a secret you're trying to uncover?"
        }
        if (ClickCount >= 93 && ClickCount < 103) {
            newPhrase = "Fine, keep clicking. See where it leads."
        }
        if (ClickCount >= 103 && ClickCount < 113) {
            newPhrase = "Are you sure you want to continue?"
        }
        if (ClickCount >= 113 && ClickCount < 123) {
            newPhrase = "Congratulations, you've unlocked something special! Just a few click left"
        }
        if (ClickCount >= 123) {
            newPhrase = "0709201611282012"
        }
        if (ClickCount > 223) {
            setClickCount(0)
        }
        else {
            setClickCount(ClickCount + 1)
        }
        setPhrases([...phrases, newPhrase]);
    };

    return (
        <>
            <div className="box">
                <div className="nav unselectable">
                    <div className="menu">
                        <ul className="soc">
                            {
                                location.pathname === "/wlchecker" ? [
                                    <>
                                        <li>
                                            <Link to="/" rel="noreferrer">
                                                Mint
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/wlchecker" rel="noreferrer" className='active'>
                                                Whitelist Checker
                                            </Link>
                                        </li>
                                    </>
                                ] : [
                                    <>
                                        <li>
                                            <Link to="/" rel="noreferrer" className='active'>
                                                Mint
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/wlchecker" rel="noreferrer" >
                                                Whitelist Checker
                                            </Link>
                                        </li>
                                    </>
                                ]
                            }

                            <li>
                                <a href={links.Twitter} target="_blank" rel="noreferrer">
                                    Twitter
                                </a>
                            </li>
                            <li>
                                <a href={links.Etherscan} target="_blank" rel="noreferrer">
                                    Etherscan
                                </a>
                            </li>
                            <li>
                                <a href={links.OpenSea} target="_blank" rel="noreferrer">
                                    Opensea
                                </a>
                            </li>
                            <li>
                                {
                                    isConnected && (
                                        <Link className="connect-btn" onClick={openAccountModal}>
                                            {ensName.data != null ? [
                                                <>
                                                    {ensName.data}
                                                </>
                                            ] : [
                                                <>
                                                    {address.substr(-6, 6)}
                                                </>
                                            ]}

                                        </Link>
                                    )
                                }
                                {
                                    isDisconnected && (
                                        <Link className="connect-btn" onClick={openConnectModal}>
                                            Connect wallet
                                        </Link>
                                    )
                                }
                            </li>
                        </ul>
                    </div>
                    <div className="mob-menu">
                        <div className="mob-menu__items" onClick={() => clicker()}>
                            <div className="header-soc">
                                {
                                    isConnected && (
                                        <div className="connect-btn" onClick={openAccountModal}>
                                            {ensName.data != null ? [
                                                <>
                                                    {ensName.data}
                                                </>
                                            ] : [
                                                <>
                                                    {address.substr(-6, 6)}
                                                </>
                                            ]}

                                        </div>
                                    )
                                }
                                {
                                    isDisconnected && (
                                        <div className="connect-btn" onClick={openConnectModal}>
                                            Connect
                                        </div>
                                    )
                                }
                                <Link to="/" rel="noreferrer">
                                    Mint
                                </Link>
                                <Link to="/wlchecker" rel="noreferrer">
                                    Whitelist Checker
                                </Link>
                                <a href={links.Twitter} target="_blank" rel="noreferrer">
                                    Twitter
                                </a>
                                <a href={links.Etherscan} target="_blank" rel="noreferrer">
                                    Etherscan
                                </a>
                                <a href={links.OpenSea} target="_blank" rel="noreferrer">
                                    Opensea
                                </a>

                            </div>
                        </div>
                    </div>
                    <div className="header__burger" id="buger" onClick={() => burger()}>
                        <span></span>
                    </div>
                </div>
                <Outlet />
            </div>
            <div class="satoshi unselectable">
                <img src="/img/satoshi.png" alt="satoshi" onClick={handleClick} />
                {phrases.map((phrase, index) => (
                    <div className="floating-phrase" key={index}>
                        {phrase}
                    </div>
                ))}
            </div>
            <Popup active={ChainError} setActive={setChainError} >
                <div className="popup-text">
                    <div className="popup-title unselectable">
                        <p>wrong chain 😢</p>
                    </div>
                    <div className="popup-text__text unselectable">
                        <p>switch to the ethereum mainnet, please.</p>
                    </div>
                </div>
            </Popup>
        </>
    )
}


export { Layout }